
import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Col, Button, Empty, Toast } from "vant";
import { isWechat, getPayMethodName } from "@/utils/index";
import config from "@/core/config";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Empty.name]: Empty,
    [Button.name]: Button,
  },
})
export default class CancelPayment extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  payMethod!: "WXPAY" | "ALIPAY" | "UNIONPAY" | "WALLET" | "ZERO";
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  orderType!: "RECHARGE" | "SHOW" | "COMMODITY" | "VIDEO" | "ACTIVITYAPPLY";
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  billNo!: string;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  orderId!: string;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  videoId!: string;

  comfirmCancel(): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    let billNo = this.billNo;
    // 确定取消支付
    this.$api.paymentApi.paymentOrder.cancelPayOrder({ billNo }, ({ data }) => {
      Toast.clear();
      if (data) {
        monitorEvent(
          "CancelPayFail",
          "支付取消",
          getPayMethodName(this.payMethod) + "取消"
        ); // 埋点：支付取消
        if (this.orderType == "RECHARGE") {
          this.$router.go(-2);
        } else if (this.orderType == "VIDEO") {
          this.$router.replace(`/cloud-theatre-details?id=${this.videoId}`);
        } else if (this.orderType == "ACTIVITYAPPLY") {
          // 取消支付 活动
          this.$router.replace({
            path: "/enter-activity",
            query: {
              isAll: `${false}`,
              selectType: "4",
            },
          });
        } else {
          this.$router.replace({
            path: "/payconfirm",
            query: {
              orderId: this.orderId,
              orderType: this.orderType,
            },
          });
        }
      } else {
        Toast.fail("取消支付失败！");
      }
    });
  }

  /**
   * 继续支付
   */
  continueToPay(): void {
    /**
     * 用倒退一页的方式不保险，发起同等条件的新支付
     * cancelOlderOrder 为 true 可以取消此业务订单对应的之前的支付订单
     */
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.$api.paymentApi.paymentOrder.paymentOrder(
      {
        channelEnum: isWechat()
          ? config.payment.channelEnumH5WX
          : config.payment.channelEnumH5,
        orderId: this.orderId,
        orderType: this.orderType,
        payMethodEnum: this.payMethod,
        sceneTypeEnum: this.$AppSource === "ios" ? "IOS_WAP" : "ANDROID_WAP",
        cancelOlderOrder: true,
        distributionChannelId: this.distributionChannelId,
        distributionSeriesId: this.distributionSeriesId,
        templateType: this.templateType,
      },
      ({ data }) => {
        Toast.clear();
        if (data && data.requestUrl) {
          window.location.href = data.requestUrl;
        } else {
          console.error(data);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
}

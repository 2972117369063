
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import payConfirm from "@/views/Payment/PayConfirm.vue";
import RechargeSuccess from "@/views/Mine/AssetCenter/ElectronicWallet/Components/RechargeSuccess.vue";
import {
  NavBar,
  Field,
  Popup,
  Picker,
  Cell,
  Switch,
  Row,
  Col,
  Image,
  Button,
} from "vant";
import knowDialog from "./KnowDialog.vue";

@Component({
  components: {
    payConfirm,
    RechargeSuccess,
    Mixin,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
    knowDialog,
  },
})
export default class Card extends Mixins(Mixin) {
  @Prop({ default: "" }) public phone!: string;
  @Prop({ default: true }) public isRecharge!: string;
  public valueStr = "";
  public amountValue = 0; // 激活卡面值
  public cardPass = ""; // 实际卡密
  public showPayConfirm = false;
  public orderId = "";
  public showStatus = false;
  public showActDesc = false;
  back(): void {
    this.valueStr = "";
    this.$emit("close");
  }
  onClickRight(): void {
    this.showActDesc = true;
  }
  closeNotice(): void {
    this.showActDesc = false;
  }
  closePopup(): void {
    this.showStatus = false;
    this.$emit("refresh");
  }
  Recharge(): void {
    if (this.isRecharge) {
      //是否在微博环境
      if (this.getIsWeiBo()) {
        return;
      }
      if (this.valueStr) {
        this.$api.orderApi.walletOrder.setRechargeOrder(
          {
            amount: this.valueStr,
          },
          ({ data }) => {
            if (data) {
              this.orderId = data;
              this.showPayConfirm = true;
              this.$nextTick(() => {
                let payConfirm = this.$refs["pay-confirm"] as payConfirm;
                if (payConfirm) {
                  payConfirm.getData({
                    amount: Number(this.valueStr),
                    orderId: this.orderId,
                    recharge: true,
                    orderType: "RECHARGE",
                  });
                }
              });
            }
          }
        );
      } else {
        this.$toast("输入充值金额");
      }
    } else {
      this.$api.orderApi.walletOrder.activateECard(
        { cardPass: this.cardPass },
        (data) => {
          this.amountValue = Number(data.data.money);
          this.valueStr = "";
          this.showStatus = true;
        }
      );
    }
  }
  checkStrLong(): void {
    if (!this.isRecharge) {
      let reg = /\s{1,}/g;
      let str = ""; //定义页面展示效果的值
      let myStr = (document.getElementById("input") as any).value;
      myStr = myStr.replace(reg, "");
      for (let i = 0; i < myStr.length; i++) {
        if (i % 4 === 0 && i > 0) {
          str = str + " " + myStr.charAt(i);
        } else {
          str = str + myStr.charAt(i);
        }
      }
      this.valueStr = str;
      this.cardPass = myStr;
    }
  }
}


import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  NavBar,
  Cell,
  CellGroup,
  Image,
  Field,
  Icon,
  Row,
  Col,
  Button,
  List,
  Popup,
  Toast,
  Dialog,
} from "vant";
import { WCollectProjectRes } from "@/shims.decorator";
import Card from "./Components/Card.vue";
import knowDialog from "./Components/KnowDialog.vue";
@Component({
  components: {
    Mixin,
    Card,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [List.name]: List,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
    knowDialog,
  },
})
export default class WalletOpend extends Mixins(Mixin) {
  public walletDetails: Array<WCollectProjectRes> = []; //明细
  public showCard = false;
  public isRecharge = false;
  public walletMsg = {
    balance: "",
    phone: "",
    freeze: false,
    extract: false,
  };
  mark = false;
  get encryPhone(): string {
    return this.walletMsg.phone.replace(
      this.walletMsg.phone.substring(3, 7),
      "****"
    );
  }
  public showActDesc = false;

  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };
  closeNotice(): void {
    this.showActDesc = false;
  }
  refresh(): void {
    this.showCard = false;
    this.$emit("refreshPage");
  }
  getData(params: {
    balance: string;
    phone: string;
    freeze: boolean;
    extract: boolean;
  }): void {
    this.walletMsg = params;
    this.getDetailList(true);
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  onClickRight(): void {
    this.showActDesc = true;
  }
  reset(): void {
    this.$router.push("/electronicWallet/check");
  }
  Recharge(): void {
    this.isRecharge = true;
    this.showCard = true;
  }
  Activation(): void {
    this.isRecharge = false;
    this.showCard = true;
  }
  getDetailList(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    this.$api.memberApi.eWallet.getEWalletDetail(
      { pageNum: this.page.current, pageSize: this.page.size },
      ({ data }) => {
        if (this.page.current === 1) {
          this.walletDetails = data.records! as Array<WCollectProjectRes>;
          this.mark = true;
        } else {
          this.walletDetails = this.walletDetails.concat(
            data.records! as Array<WCollectProjectRes>
          );
        }
        this.list.loading = false;
        this.list.finished = data.current! >= data.pages!;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  ApplyCash(): void {
    this.$api.memberApi.eWallet.getEWalletInfo(({ data }) => {
      let freeze = data.freeze!;
      let extract = data.extract;
      if (freeze) {
        this.$router.push(`/wallet-apply-cash?extract=${extract}`);
      }
    });
  }
}

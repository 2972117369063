
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import { NavBar, Row, Col, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
  },
})
export default class RechargeSuccess extends Mixins(Mixin) {
  @Prop({ default: "", type: String }) public amount!: string;

  onClickLeft(): void {
    this.$emit("close");
  }
}


import { Component, Mixins, Prop, Watch, Vue } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import navbar from "@/components/NavBar.vue";
import DistributionArea from "./DistributionArea.vue";
import { isWechat, getPayMethodName, isWeiBo } from "@/utils/index";
import config from "@/core/config";
// import { CountDownModule } from "@/store/modules/countDown";
import { monitorEvent } from "@/utils/youMengMonitor";
// import weChatPay from './PayWay/WeChatPay.vue';

declare type OrderType =
  | "SHOW"
  | "RECHARGE"
  | "COMMODITY"
  | "VIDEO"
  | "ACTIVITYAPPLY";

import {
  CountDown,
  Col,
  Row,
  NavBar,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Popup,
  Field,
  SubmitBar,
  CellGroup,
  Dialog,
  RadioGroup,
  Radio,
  PasswordInput,
  NumberKeyboard,
} from "vant";
import { Route } from "vue-router";

@Component({
  components: {
    [CountDown.name]: CountDown,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Popup.name]: Popup,
    [SubmitBar.name]: SubmitBar,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Dialog.Component.name]: Dialog.Component,
    [PasswordInput.name]: PasswordInput,
    [NumberKeyboard.name]: NumberKeyboard,
    Mixin,
    navbar,
    DistributionArea,
    // weChatPay
  },
})
export default class PayConfirm extends Mixins(Mixin) {
  public orderExpireTimeShow = false; //倒计时 是否显示，确保数据一定加载完成
  public orderExpireTime = 0; //倒计时
  public submitLoading = false;
  public payData: payment.PaymentOrderResponseDTO = {}; //支付信息
  public wxPay = false; // 微信 支付
  public pacPay = false; // 余额 支付
  public aliPay = false; // 支付宝 支付
  public unionPay = false; //银联 支付

  public walFreeze = false; //余额冻结 true：冻结；false：未冻结；
  public noWal = false; //开通余额 true:开通，false:未开通
  public nofullmoney = false;
  public defaultname = "";
  public totalmoney = "";
  public payMoney: string | (string | null)[] = "";
  public totalmoney_c: string | (string | null)[] = "";

  public packmoney = -1;
  public expireTime = 0; //过期时间

  public cultureCoupon: Array<good.OrderTicketCouponDetail> = []; //文惠券列表
  public showWHCoupon = false;
  public orderId = "";
  public activityId = "";
  public productSourceEnum = "";
  public orderType: OrderType = "SHOW";
  public WalletInfo: member.EWalletDTo = {}; //余额信息
  public balance = "";
  public cancelOlderOrder = false; //订单取消
  public channelEnum:
    | "PLAT_H5"
    | "PLAT_H5_WX"
    | "THEATRE_H5"
    | "THEATRE_H5_WX" = config.payment.channelEnumH5; //频道

  public walletPassword = "";
  public showWalletPay = false;
  public orderInfo = {};
  public actuallyPaidAmt = 0;
  public showKeyboard = true;
  public showKeyboards = false;
  public passwordValue = "";
  public amount = 0;
  // public wxPayShow = 0;
  public productId = "";

  @Prop({ default: false })
  public recharge!: boolean;

  @Watch("passwordValue")
  passwordValueChange(val: string): void {
    if (val.length === 6) {
      this.showWalletPayConfirm();
    }
  }
  @Watch("$route")
  changeRoute(to: Route): void {
    console.log("routechange", to);
  }

  created(): void {
    if (!this.recharge) {
      this.init();
    }
    console.log("created");
  }

  mounted(): void {
    console.log(
      "PayConfirm.vue >>> mounted() >>> this.$route.query.templateType：",
      this.$route.query.templateType
    );
    console.log(
      "PayConfirm.vue >>> mounted() >>> this.templateType：",
      this.templateType
    );
  }

  /**
   * 初始化数据
   */
  init() {
    this.$toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    let query = this.$route.query;

    if (query && !Array.isArray(query)) {
      let orderId = String(query.orderId || "");
      let orderTypeOld = String(query.orderType || "");
      let productId = !!query.productId
        ? String(query.productId)
        : this.productIdModule.productId;
      if (orderTypeOld == "ORDER_GOOD") {
        orderTypeOld = "COMMODITY";
      } else if (orderTypeOld == "ORDER_TICKET") {
        orderTypeOld = "SHOW";
      }
      let orderType = orderTypeOld as OrderType;
      this.activityId = String(query.activityId || "");
      this.orderType = orderType;
      this.orderId = orderId;
      this.productId = productId;

      this.$api.orderApi.ticketOrder.orderPayInfo(
        orderId,
        orderType,
        ({ data }) => {
          //倒计时模块所需时间信息
          this.orderExpireTime = data.orderExpireTime!;
          this.orderExpireTimeShow = true; //确保倒计时 加载完成 ，再显示 倒计时
          this.$nextTick(() => {
            let countdown = this.$refs["countDown"] as CountDown;
            countdown.start();
          });
          //区分 是来自 第三方 还是 来自 平台
          this.productSourceEnum = data.productSourceEnum || "";
          //支付金额的处理
          let actuallyPaidAmt = data.actuallyPaidAmt || 0;
          this.totalmoney = `¥${actuallyPaidAmt!.toFixed(2)}`;
          this.payMoney = `${actuallyPaidAmt!.toFixed(2)}`;
          this.actuallyPaidAmt = actuallyPaidAmt!;
          //是否支持余额支付
          this.pacPay = data.supportPurse!;

          this.getEleInfos();
          //初始化支付方式
        }
      );
    }
  }

  getData(params: {
    orderType: OrderType;
    orderId: string;
    recharge: boolean;
    amount: number;
  }): void {
    this.orderType = params.orderType;
    this.orderId = params.orderId;
    this.recharge = params.recharge;
    this.amount = params.amount;
    this.totalmoney_c = Number(this.amount).toFixed(2);
    this.totalmoney = `¥${Number(this.amount).toFixed(2)}`;
    this.payMoney = Number(this.amount).toFixed(2);
    this.getEleInfos();
  }

  /**
   *  初始化支付方式
   * **/
  initPayWay(): void {
    //判断是否是渠道分销
    this.pacPay =
      this.orderType === "SHOW"
        ? this.isChannelDistribution
          ? false
          : this.pacPay
        : false;
    //判断是否是 微信内置环境
    (isWechat()
      ? () => {
          this.wxPay = true; //微信支付
          this.unionPay = true; //银联支付
        }
      : () => {
          this.aliPay = true; //支付宝支付
          this.unionPay = true; //银联支付
        })();

    if (this.pacPay && !this.noWal) {
      //true 已冻结
      this.defaultname = this.walFreeze ? "" : "1";
    } else if (this.wxPay) {
      this.defaultname = "";
    } else if (this.aliPay) {
      this.defaultname = "";
    } else if (this.unionPay) {
      this.defaultname = "";
    } else {
      this.defaultname = "";
    }
    this.$toast.clear();
  }
  /**
   * 余额支付方式 展示输入密码确认弹框
   */
  showWalletPayConfirm(): void {
    this.$toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.$api.paymentApi.paymentOrder.payByWallet(
      {
        cancelOlderOrder: this.cancelOlderOrder,
        channelEnum: this.channelEnum,
        orderId: this.orderId,
        orderType: this.orderType,
        walletPassword: this.passwordValue,
      },
      ({ data }) => {
        if (data) {
          this.productIdModule.SET_ProductId_PERSIST(this.productId);
          this.showWalletPay = false;

          this.cancelOlderOrder = false;
          this.$toast.clear();
          this.$router.replace({
            path: `/paysuccess/ticket`,
            query: {
              status: "TRADE_SUCCESS",
              orderId: this.orderId,
              orderType: this.orderType,
              productSourceEnum: this.productSourceEnum,
              productId: this.productId,
            },
          });
        } else {
          monitorEvent("PayFail", "支付失败", "余额"); // 埋点：确认支付页，支付失败
        }
      },
      (error) => {
        monitorEvent("PayFail", "支付失败", "余额"); // 埋点：确认支付页，支付失败
        this.passwordValue = "";
        this.cancelOlderOrder = true;
        error && this.$toast(error);
        if (error === "该订单在支付中，是否重新发起支付？") {
          Dialog.confirm({
            message: `${error}`,
          }).then(() => {
            this.onSubmit();
          });
        } else {
          error && this.$toast(error);
          if (error === "该订单已经支付成功，不允许重复支付。") {
            if (this.orderType === "COMMODITY") {
              //回退到商品订单详情
              this.$router.replace(`/mall/m-order/${this.orderId}`);
            } else if (this.orderType === "ACTIVITYAPPLY") {
              //回退到活动报名
              this.$router.replace(
                `/apply-enter-activity?activityId=${this.activityId}`
              );
            } else {
              this.$router.replace(
                `/performance/per-order/${this.orderId}/${this.productSourceEnum}`
              );
            }
          }
        }
      }
    );
  }
  /**
   * 获取用户的 余额 相关信息
   */
  getEleInfos(): void {
    this.$api.memberApi.eWallet.getEWalletInfo(({ data }) => {
      this.WalletInfo = data;
      if (this.WalletInfo.open) {
        //开通余额
        //开通 余额
        this.noWal = false;

        if (!this.WalletInfo.freeze) {
          //未冻结
          this.walFreeze = false;
          if (this.WalletInfo.balance !== undefined) {
            this.balance = `${Number(this.WalletInfo.balance!).toFixed(2)}`;
            //余额不足
            if (this.WalletInfo.balance - this.actuallyPaidAmt < 0) {
              this.nofullmoney = true;
            } else {
              this.nofullmoney = false;
              // this.packmoney = this.WalletInfo.balance.toFixed(2);
            }
          }
        } else {
          //已冻结
          this.walFreeze = true;
          this.balance = `${Number(this.WalletInfo.balance!).toFixed(2)}`;
        }
      } else {
        //未开通 余额
        this.noWal = true;
      }
      this.initPayWay();
    });
  }
  /**
   * 忘记密码
   */
  goToRePass(): void {
    this.$router.push("/electronicWallet/check");
  }
  /**
   * 倒计时结束 触发 交易关闭
   */
  onFinish(): void {
    this.$toast("交易关闭");
    if (this.orderType === "COMMODITY") {
      //回退到商品订单详情
      this.$router.replace(`/mall/m-order/${this.orderId}`);
    } else if (this.orderType === "ACTIVITYAPPLY") {
      //回退到活动报名
      this.$router.replace(
        `/apply-enter-activity?activityId=${this.activityId}`
      );
    } else {
      this.$router.replace(
        `/performance/per-order/${this.orderId}/${this.productSourceEnum}`
      );
    }
  }

  /**
   * 返回并删除订单
   */
  onClickLeft(): void {
    //console.log("物理键返回");
    Dialog.confirm({
      message: "支付未完成，确认离开？",
    }).then(() => {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      if (this.recharge) {
        this.$router.go(-1);
      } else {
        if (this.orderType === "COMMODITY") {
          this.$toast.clear();
          //回退到商品订单详情
          this.$router.replace(`/mall/m-order/${this.orderId}`);
        } else if (this.orderType === "ACTIVITYAPPLY") {
          this.$toast.clear();
          //回退到活动报名
          // this.$router.replace(
          //   `/apply-enter-activity?activityId=${this.activityId}`
          // );
          this.$router.replace({
            path: "/enter-activity",
            query: {
              isAll: `${false}`,
              selectType: "4",
            },
          });
        } else {
          this.productIdModule.SET_ProductId_PERSIST(this.productId);

          this.$toast.clear();
          this.$router.replace(
            `/performance/per-order/${this.orderId}/${this.productSourceEnum}`
          );
        }
      }
    });
  }
  /**
   * 提交订单
   */
  onSubmit(): void {
    // 是否是微信内置环境
    if (isWechat()) {
      this.channelEnum = config.payment.channelEnumH5WX;
    } else {
      this.channelEnum = config.payment.channelEnumH5;
    }

    // 支付方式
    if (this.defaultname === "1") {
      this.showWalletPay = true;
      monitorEvent("ViewOrder_ClickPayMethod", "点击确认支付", "余额"); // 埋点：确认支付页，点击确认支付
      return;
    }
    let payMethodEnum: "WXPAY" | "ALIPAY" | "UNIONPAY" | "WALLET" = "WALLET";
    if (this.defaultname === "2") {
      payMethodEnum = "WXPAY";
    } else if (this.defaultname === "3") {
      payMethodEnum = "ALIPAY";
    } else if (this.defaultname === "4") {
      payMethodEnum = "UNIONPAY";
    }
    let payMethodName = getPayMethodName(payMethodEnum);
    monitorEvent("ViewOrder_ClickPayMethod", "点击确认支付", payMethodName); // 埋点：确认支付页，点击确认支付

    // 是否来自余额
    let orderId = "";
    if (this.recharge) {
      orderId = this.orderId!;
    } else {
      orderId = this.orderId;
    }
    // 判断订单类型
    let orderType:
      | "COMMODITY"
      | "SHOW"
      | "VIDEO"
      | "RECHARGE"
      | "ACTIVITYAPPLY" = "COMMODITY";
    if (this.orderType === "SHOW") {
      //演出订单
      orderType = "SHOW";
    }
    if (this.orderType === "COMMODITY") {
      //商品订单
      orderType = "COMMODITY";
    }
    if (this.orderType === "ACTIVITYAPPLY") {
      //活动报名
      orderType = "ACTIVITYAPPLY";
    }
    if (this.recharge) {
      orderType = this.orderType;
    }
    this.submitLoading = true;

    this.$api.paymentApi.paymentOrder.paymentOrder(
      {
        channelEnum: this.channelEnum,
        orderId: orderId,
        orderType: orderType, //COMMODITY
        payMethodEnum: payMethodEnum,
        sceneTypeEnum: this.$AppSource === "ios" ? "IOS_WAP" : "ANDROID_WAP",
        cancelOlderOrder: this.cancelOlderOrder,
        distributionChannelId: this.distributionChannelId,
        distributionSeriesId: this.distributionSeriesId,
        templateType: this.templateType,
      },
      ({ data }) => {
        this.cancelOlderOrder = false;
        if (data) {
          this.payData = data;
          this.productIdModule.SET_ProductId_PERSIST(this.productId);
          window.location.href = this.payData.requestUrl!;
        } else {
          this.submitLoading = false;
          monitorEvent("PayFail", "支付失败", payMethodName); // 埋点：确认支付页，支付失败
        }
      },
      (error) => {
        console.error("PayConfirm.vue >>> paymentOrder() >>> error：", error);
        monitorEvent("PayFail", "支付失败", payMethodName); // 埋点：确认支付页，支付失败
        this.submitLoading = false;
        this.cancelOlderOrder = true;
        if (error === "该订单在支付中，是否重新发起支付？") {
          Dialog.confirm({
            message: `${error}`,
          }).then(() => {
            this.onSubmit();
          });
        } else {
          error && this.$toast(error);
          if (error === "该订单已经支付成功，不允许重复支付。") {
            if (this.orderType === "COMMODITY") {
              //回退到商品订单详情
              this.$router.replace(`/mall/m-order/${this.orderId}`);
            } else if (this.orderType === "ACTIVITYAPPLY") {
              //回退到活动报名
              this.$router.replace(
                `/apply-enter-activity?activityId=${this.activityId}`
              );
            } else {
              this.$router.replace(
                `/performance/per-order/${this.orderId}/${this.productSourceEnum}`
              );
            }
          }
        }
      }
    );
  }
}


import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Vue } from "vue-property-decorator";
import { Button } from "vant";
@Component({
  components: {
    [Button.name]: Button,
  },
})
export default class DistributionArea extends Mixins(Mixin) {
  @Prop({ default: Array }) public newAddrDistributionScope!: Array<string>;
}


import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  NavBar,
  Cell,
  CellGroup,
  Image,
  Field,
  Icon,
  Row,
  Col,
  Button,
  List,
} from "vant";
@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [List.name]: List,
  },
})
export default class WalletNotOpen extends Mixins(Mixin) {
  @Prop({ default: false, type: Boolean }) public isRealName!: boolean;
  public text = "";

  created(): void {
    this.getTipsText();
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  OpenWallet(): void {
    if (this.isRealName) {
      this.$router.push("/electronicWallet/check");
    } else {
      this.$router.push(`/personal-data/real-name/${2}`);
    }
  }
  getTipsText(): void {
    this.$api.cmsApi.commonInfo.policy("TIPS_USING", ({ data }) => {
      this.text = data;
    });
  }
  openNotice(): void {
    let type = "WALLET_AGREEMENT";
    let name = "余额服务协议";
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
}
